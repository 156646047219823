import { Box, Stack } from "@mui/material";
import { FC, useState } from "react";

import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { AnalysisSubHead } from "components/AnalysisSubHead";
import { AnalysisSummaryConnected } from "./AnalysisSummary";
import { AnalysisTableConnected } from "./AnalysisTable";
import { CustomButton } from "components/Button/CustomButton";
import { CustomMenuItem } from "components/CustomMenu";
import { NewAnalysisModal } from "./NewAnalysis/NewAnalysisModal";
import { Typo14Medium } from "components/Typography/Typo14Medium";
import { Typo16 } from "components/Typography/Typo16";
import { Typo24Bold } from "components/Typography/Typo24Bold";
import { baseStyles } from "utils/constants/baseStyles";
import { useTranslation } from "react-i18next";

const AnalysisLists: FC = (props: any) => {
  const { t } = useTranslation();
  
  const [openNewAnalysisModal, setOpenNewAnalysisModal] = useState<boolean>(false);

  const analysisSubHeadMenus: CustomMenuItem[] = [
    { label: `JSON (${t('soon')})`, onClick: () => { }, disabled: true },
    { label: "CSV", onClick: () => setOpenNewAnalysisModal(true) },
    { label: `PDF (${t('soon')})`, onClick: () => {}, disabled: true }
  ];

  return (
    <>
      <Box sx={baseStyles.container}>
        <Stack gap={"50px"} overflow={"auto"}>
          {/* Title */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px"
            }}
          >
            <Stack gap="10px">
              <Typo24Bold sx={{ lineHeight: "18px", letterSpacing: "0.5px" }}>{t('analysisList')}</Typo24Bold>
              <Typo14Medium color="primary-blue-60">{t('textDescriptionNewAnalysis')}</Typo14Medium>
            </Stack>
            <CustomButton
              sx={{
                height: "60px",
                paddingLeft: "20px",
                paddingRight: "0px",
                letterSpacing: "0.5px",
                width: "271px"
              }}
              icon={<AddCircleOutlineOutlined />}
              onClick={() => setOpenNewAnalysisModal(true)}
            >
              <Typo16
                color="white"
                weight="medium"
                sx={{ paddingRight: "20px", minWidth: "fit-content" }}
              >
                {t('newAnalysis')}
              </Typo16>
            </CustomButton>
          </Box>
          
          {/* Summary of analysis */}
          <AnalysisSummaryConnected />

          {/* Table liste of all analysis */}
          <AnalysisTableConnected />
        </Stack>
      </Box>

      {/* Modal */}
      <NewAnalysisModal
        open={openNewAnalysisModal}
        handleClose={() => {
          setOpenNewAnalysisModal(false);
        }}
        handleRunAnalysis={() => {
          console.log("Run analysis");
        }}
      />
    </>
  );
};

export default AnalysisLists;
