import { Box, Button, Grid, IconButton, Stack } from "@mui/material";
import { CreateNewAnalysisPayload, FilePayload } from "services/Analysis/AnalysisService";
import React, { ChangeEvent, useState } from "react";
import { Statement, StatementFormProps } from "types/analysis/statement";
import { addStatement, removeStatement, selectStatementsState, setStatements } from "store/slices/statementsSlice";
import { getBanks, getMobileMoneyCompanies } from "services/Bank/BankService";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useCreateAnalysisMutation, useGetBankCountriesQuery, useUpdateAnalysisMutation } from "store/api";

import CloseIcon from "@mui/icons-material/Close";
import { Currency } from "types/analysis/analysis";
import { CustomButton } from "components/Button/CustomButton";
import { CustomInput } from "components/InputField/CustomInput";
import CustomModal from "components/CustomModal/CustomModal";
import CustomSelect from "components/InputField/CustomSelect";
import DragableFileInput from "components/InputField/DragableFileInput";
import { FileIcon } from "components/Icons/SvgIcons";
import LoadingAnalysis from "./LoadingAnalysis";
import { StatementLine } from "./StatementLine/StatementLine";
import { Typo14 } from "components/Typography/Typo14";
import { Typo14Medium } from "components/Typography/Typo14Medium";
import { access } from "fs";
import { baseStyles } from "utils/constants/baseStyles";
import { generateRandomNumberId } from "utils/random";
import { getCurrencies } from "services/Bank/CurrencyService";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

type NewAnalysisModalProps = {
  open: boolean;
  handleClose: any;
  handleRunAnalysis: any;
};

export type Status = "PENDING" | "DONE" | "FAILED" | "ERROR_FILE";

export const NewAnalysisModal = (props: NewAnalysisModalProps) => {
  const { t } = useTranslation();
  
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  const { open, handleClose, handleRunAnalysis } = props;
  const [analysisForm, setAnalysisForm] = useState<CreateNewAnalysisPayload>({
    clientRef: undefined,
    clientName: undefined,
    currencyCode: undefined,
    countryCode: "",
    applicationId: "",
    files: []
  });
  const [status, setStatus] = useState<Status>("PENDING");
  const [analysisMessage, setAnalysisMessage] = useState<string>('');
  const [showBgBlur, setShowBgBlur] = useState<Boolean>(false);
  const [selectedFile, setSelectedFile] = useState<AnalysisFile | undefined>(undefined);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [banks, setBanks] = useState<Bank[]>([]);
  const [mobileMoneyCompanies, setMobileMoneyCompanies] = useState<MobileMoneyCompany[]>([]);
  const [institutions, setInstitutions] = useState<Bank[] | MobileMoneyCompany[]>([]);
  
  const [isCanceled, setIsCanceled] = useState(false);

  const [createAnalysis, { data: createdAnalysis }] = useCreateAnalysisMutation();
  const [updateAnalysis] = useUpdateAnalysisMutation();
  const { statements } = useAppSelector(selectStatementsState);
  
  const retry = () => {
    console.log("retry");
    runAnalysis();
  };

  const cancel = () => {
    console.log("cancel");
    setShowBgBlur(false);
    setIsCanceled(true);
  };

  const close = () => {
    console.log("close");
    setShowBgBlur(false);
  };

  const runAnalysis = () => {
    setShowBgBlur(true);
    setIsCanceled(false);

    setStatus("PENDING");

    const analysisFormWithStatements = addStatementsToAnalysisForm();
    
    createAnalysis(analysisFormWithStatements)
      .unwrap()
      .then(() => {
        setStatus("DONE");
        dispatch(setStatements([]));
      })
      .catch((error) => {
        console.log(error);
        setStatus("FAILED");
        
        if (error?.data?.errors?.errors.length > 0 && 'code' in error.data.errors.errors[0]) {
          const message = getAnalysisMessageFromCode(error.data.errors.errors[0]['code']);
          console.log(message);
          setAnalysisMessage(message);
        }
      });
    handleRunAnalysis();
  };

  const getAnalysisMessageFromCode = (code: string) => {
    const correspondingMessages: {[code: string]: string} = {
      'WRONG_PASSWORD': t('wrongPassword')
    }

    if (code in correspondingMessages) {
      return correspondingMessages[code];
    }

    return code;
  };
  
  const addStatementsToAnalysisForm = () => {
    const r = {
      ...analysisForm,
      files: statements
    };
    console.log('r', r);
    return r;
  };

  const isValidForm = () => {
    const isAnalysisFieldsValid = analysisForm.clientRef && analysisForm.clientName;
    
    let isAllStatementsValid: boolean = true;
    
    if (statements.length === 0) {
      return false;
    }
    
    statements.map((statement: StatementFormProps) => {
      if (!isStatementLineValid(statement)) {
        isAllStatementsValid = false;

        return false;
      }

      return true;
    });

    return isAnalysisFieldsValid && isAllStatementsValid;
  };

  const isStatementLineValid = (statementLine: StatementFormProps) => {
    if (statementLine.passwordRequired && !statementLine.accessCode) {
      return false;
    }

    return (
      statementLine.analysisTypeCode && 
      statementLine.fileTypeCode &&
      statementLine.institutionId &&
      statementLine.base64data
    )
  };

  const notifyChange = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    const value = e.target.value;
    setAnalysisForm((oldState) => ({
      ...oldState,
      [key]: value
    }));
  };
  
  const handleAddStatement = () => {
    const randomId = generateRandomNumberId();
    const newStatement = {
      id: randomId
    } as Statement;
    
    dispatch(addStatement(newStatement));
  };

  React.useEffect(() => {
    getCurrencies()
      .then((response) => {
        setCurrencies(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const { data: bankCountries } = useGetBankCountriesQuery({});
  
  React.useEffect(() => {
    if (bankCountries) {
      setCountries(bankCountries);
    }
  }, [bankCountries]);
  
  // Cancel analysis
  React.useEffect(() => {
    if (isCanceled && createdAnalysis) {
      updateAnalysis({ id: createdAnalysis.id, statusCode: "CANCELED" });
    }
  }, [isCanceled, createdAnalysis, updateAnalysis]);

  // Redirect to analysis result after 1s
  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (createdAnalysis && !isCanceled) {
      timeout = setTimeout(() => {
        navigate(`analysis/${createdAnalysis.id}/account-overview`);
      }, 1000);
    }

    return () => clearTimeout(timeout);
  }, [createdAnalysis, isCanceled, navigate]);

  return (
    <Box sx={{ position: "relative" }}>
      <CustomModal
        title={t('newAnalysis')}
        open={open}
        handleClose={handleClose}
        closeButton={true}
        footer={
          <Box display={"flex"} gap="30px" marginTop={"20px"} height={'auto'}>
            <CustomButton sx={{ flexGrow: 1 }} color="secondary" onClick={handleClose}>
              {t('cancel')}
            </CustomButton>
            <CustomButton
              sx={{ flexGrow: 2 }}
              color="primary"
              onClick={runAnalysis}
              disabled={!isValidForm()}
            >
              {t('launchAnalysis')}
            </CustomButton>
          </Box>
        }
        bgBlur={
          showBgBlur && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                backdropFilter: "blur(10px)",
                borderRadius: "20px",
                boxSizing: "border-box",
                border: "none",
                zIndex: 1000
              }}
            >
              <LoadingAnalysis status={status} message={analysisMessage} onRetry={retry} onCancel={cancel} onClose={close} />
            </Box>
          )
        }
        width={"70vw"}
        height={"80vh"}
      >
        <Stack gap={"30px"}>
          <Grid container spacing={"10px"}>
            <Grid item xs={6}>
              <Stack gap={"20px"}>
                <Typo14Medium>{t('clientId')}* : </Typo14Medium>
                <CustomInput
                  sx={{ marginBottom: 0 }}
                  fullWidth
                  isValid={true}
                  onchange={(e) => {
                    notifyChange(e, "clientRef");
                  }}
                  type="text"
                  placeholder={`${t('clientId')}`}
                  error={analysisForm.clientRef == ""}
                  required
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack gap={"20px"}>
                <Typo14Medium>{t('applicationId')}: </Typo14Medium>
                <CustomInput
                  sx={{ marginBottom: 0 }}
                  fullWidth
                  isValid={true}
                  onchange={(e) => {
                    notifyChange(e, "applicationId");
                  }}
                  type="text"
                  placeholder={`${t('applicationId')}`}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack gap={"10px"}>
                <Typo14Medium>{t('clientName')}* : </Typo14Medium>
                <CustomInput
                  sx={{ marginBottom: 0 }}
                  fullWidth
                  isValid={true}
                  onchange={(e) => {
                    notifyChange(e, "clientName");
                  }}
                  type="text"
                  placeholder={`${t('clientName')}`}
                  error={analysisForm.clientName == ""}
                  required
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack gap={"20px"}>
                <Typo14Medium>{t('country')}: </Typo14Medium>
                <CustomSelect
                  options={countries.map((c) => ({ value: c.code, element: c.name }))}
                  onChange={(e: any) => {
                    notifyChange(e, "countryCode");
                  }}
                  value={analysisForm.countryCode}
                ></CustomSelect>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack gap={"20px"}>
                <Typo14Medium>{t('currency')} ({t('optional')}): </Typo14Medium>
                <CustomSelect
                  options={currencies.map((c) => ({ value: c.code, element: c.name }))}
                  onChange={(e: any) => {
                    notifyChange(e, "currencyCode");
                  }}
                  value={analysisForm.currencyCode}
                ></CustomSelect>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={"10px"}>
            {
              statements.map(statement => (
                <Grid item xs={12} key={statement.id}>
                  <StatementLine statement={statement}></StatementLine>
                </Grid>
              ))
            }
          </Grid>
          <Box><Button onClick={handleAddStatement}><Typo14>+ {t('addStatement')}</Typo14></Button></Box>
        </Stack>
      </CustomModal>
    </Box>
  );
};
