import { GetManyAnalysisQueryParams, useGetManyAnalyzesQuery } from "store/api";
import { useContext, useEffect, useState } from "react";

import { Analysis } from "types/analysis/analysis";
import { AnalysisFilters } from "../AnalysisFilters";
import { AnalysisTable } from "./AnalysisTable";
import { Box } from "@mui/material";
import { CustomTablePagination } from "components/CustomTablePagination";
import React from "react";
import { UserContext } from "providers/UserProvider";
import { selectTimeSelectorState } from "store/slices";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";

export const AnalysisTableConnected = () => {
  const { t } = useTranslation()
    ;
  const { currentUser } = useContext(UserContext);

  const [analyzesQueryParams, setAnalyzesQueryParams] = useState<GetManyAnalysisQueryParams>({
    ref: ""
  });

  const timeSelectorFilters = useAppSelector(selectTimeSelectorState);
  
  const defaultRowsPerPage = 10;
  const [tableData, setTableData] = useState<Analysis[]>([]);

  const {
    data: analyzes,
    error: _analyzesError,
    isLoading: isLoadingAnalyzes,
    isFetching: isFetchingAnalyzes
  } = useGetManyAnalyzesQuery(analyzesQueryParams);

  const analyzesLoading = isLoadingAnalyzes || isFetchingAnalyzes;
  const analyzesError = _analyzesError
    ? t('anErrorOccuredDuringAnalysisRetrieve')
    : "";

  const handleChangeFilters = (name: keyof GetManyAnalysisQueryParams, value: string) => {
    setAnalyzesQueryParams((prev) => ({ ...prev, [name]: value }));
  };

  // Add default filter by responsibleId
  useEffect(() => {
    setAnalyzesQueryParams((prev) => ({ ...prev, responsibleId: currentUser?.id }));
  }, [currentUser]);

  useEffect(() => {
    if (analyzes) {
      setTableData(analyzes.slice(0, defaultRowsPerPage));
      console.log('data', analyzes.slice(0, defaultRowsPerPage));
    }
  }, [analyzes]);

  React.useEffect(() => {
    console.log('startDate is ', timeSelectorFilters.startDate);
    console.log('endDate is ', timeSelectorFilters.endDate);
    setAnalyzesQueryParams((prev) => ({
      ...prev,
      startDate: timeSelectorFilters.startDate,
      endDate: timeSelectorFilters.endDate
    }));
  }, [timeSelectorFilters]);

  return (
    <>
      <Box>
        {/* Filter by entering keywords */}
        <AnalysisFilters
          filters={analyzesQueryParams}
          loading={analyzesLoading}
          resultsCount={analyzes?.length || 0}
          onChangeFilters={handleChangeFilters}
        />
      </Box>
      <Box>
        {/* Table liste of all analysis */}
        <AnalysisTable data={tableData} loading={analyzesLoading} error={analyzesError} />
      </Box>
      {analyzes && analyzes.length > 0 && (
        <Box>
          <CustomTablePagination data={analyzes} updateData={(newData) => setTableData(newData)} />
        </Box>
      )}
    </>
  );
};
