import { ANALYSIS_RESULTS_PAGE_ID } from "constants/dom";
import AccountManagement from "pages/AccountManagement/AccountManagement";
import AnalysisLists from "pages/AnalysisLists/AnalysisLists";
import AnalysisResults from "pages/AnalysisResults/AnalysisResults";
import { AnalysisResultsDate } from "pages/AnalysisResults/AnalysisResultsDate";
import { BankProvider } from "providers/BankProvider";
import { ClienteleProvider } from "providers/ClienteleProvider";
import DashboardWrapper from "components/Dashboard/DashboardWrapper";
import { ForgetPassword } from "pages/Login/ForgetPassword";
import { HeaderDate } from "components/HeaderDate";
import Home from "pages/Home/Home";
import Login from "../pages/Login/Login";
import MessagerieWrapper from "pages/Messagerie/MessagerieWrapper";
import { MyAccount } from "pages/MyAccount/MyAccount";
import { ResetPassword } from "pages/Login/ResetPassword";
import { StatsDatesFilters } from "components/StatsDatesFilters";
import Supports from "pages/Supports/Supports";
import { Trans } from "react-i18next";
import { createBrowserRouter } from "react-router-dom";

export const ROUTES = {
  home: "/",
  adminHome: "/admin",
  login: "/login",
  forgetPassword: "/forget-password",
  resetPassword: "/reset-password"
};

const routers = createBrowserRouter([
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "",
    element: (
      <DashboardWrapper ongletTitle='analysisList'>
        <AnalysisLists />
      </DashboardWrapper>
    )
  },
  {
    path: "analysis/:id/:activeTab",
    element: (
      <DashboardWrapper
        ongletTitle='analysisResults'
        backButton={true}
        topBarChildren={<AnalysisResultsDate />}
        currentPage={ANALYSIS_RESULTS_PAGE_ID}
      >
        <AnalysisResults />
      </DashboardWrapper>
    )
  },
  {
    path: "my-account",
    element: (
      <DashboardWrapper ongletTitle='settings' backButton={true}>
        <MyAccount />
      </DashboardWrapper>
    )
  },
  {
    path: "supports",
    element: (
      <DashboardWrapper ongletTitle='Support'>
        <Supports />
      </DashboardWrapper>
    )
  },
  {
    path: "admin/",
    children: [
      {
        path: "",
        element: (
          <DashboardWrapper ongletTitle='statistics' topBarChildren={<StatsDatesFilters />}>
            <BankProvider>
              <ClienteleProvider>
                <Home />
              </ClienteleProvider>
            </BankProvider>
          </DashboardWrapper>
        )
      },
      {
        path: "manage-accounts",
        element: (
          <DashboardWrapper ongletTitle='accountManagement' topBarChildren={<HeaderDate />}>
            <BankProvider>
              <AccountManagement />
            </BankProvider>
          </DashboardWrapper>
        )
      },
      {
        path: "my-account",
        element: (
          <DashboardWrapper ongletTitle='accountParameters'>
            <MyAccount />
          </DashboardWrapper>
        )
      },
      {
        path: "messageries",
        element: (
          <DashboardWrapper ongletTitle='messaging' topBarChildren={<HeaderDate />}>
            <MessagerieWrapper />
          </DashboardWrapper>
        )
      }
    ]
  },
  {
    path: "/forget-password",
    element: <ForgetPassword />
  },
  {
    path: ROUTES.resetPassword,
    element: <ResetPassword />
  }
]);

export default routers;
